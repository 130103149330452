/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState, React } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatientWithId } from "../../../redux/patient/actions";
import Breadcrumb from "../../../components/Breadcrumb";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import AddFormModal from "./AddFormModal";
import Table from "../../../components/tables/table";
import { FaPencilAlt } from "react-icons/fa";
import {
  extra_feedback,
  view_patients_columns,
} from "../../../components/tables/tableheader";
import {
  getAllPatientForms,
  getPatientDetails,
} from "../../../redux/patientForm/actions";
import { RiEarthFill } from "react-icons/ri";
import moment from "moment";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import {
  BsFilePersonFill,
  BsFillTelephoneFill,
  BsGenderAmbiguous,
} from "react-icons/bs";
import { GrCheckmark } from "react-icons/gr";
import { RxCross2 } from "react-icons/rx";
import AddDocumentModal from "./AddDocumentModal";
import { FaHospitalAlt, FaUserAlt } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import { getAllDocuments } from "../../../redux/patientForm/actions";
import Modal from "../../../components/Modal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { Formik } from "formik";
import { toast } from "react-hot-toast";
import AddModal from "./addchart";
import UploadPedigreeChartModal from "./UploadPedigreeChartModal";
import { PDFViewer } from "@react-pdf/renderer";
import { Document, Page } from "react-pdf";
import app_api from "../../../utilities/api";
const API = process.env.REACT_APP_API_URL;

const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    purchase_order_id: "",
    storage_location_id: "",
    quantity: "",
    item_id: "",
  },
};

function Viewpatient() {
  const pages = [{ title: "View Patient", href: "/view-patient" }];
  const [showModal, setShowModal] = useState(false);
  const [DshowModal, setDShowModal] = useState(false);
  const [pedigreeUploadModel, setPedigreeUploadModel] = useState(false);
  const [CshowModal, setCShowModal] = useState(false);

  const [edit_doc_id, setEditDocId] = useState("");
  const [edit_doc_name, setEditDocName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: patientId } = useParams();

  const { patientData } = useSelector((state) => state.patient);
  const program_info_count = useSelector((state) => state.program_info_count);
  // console.log(program_info_count, "program_info_count");
  const { list: patientFormsList } = useSelector((state) => state.patientForm);
  const { loading, Newlist: patientFormList } = useSelector(
    (state) => state.patientForm
  );
  // console.log(patientFormList, "patientFormList");
  const { loading: docloading, docList: documentList } = useSelector(
    (state) => state.patientForm
  );

  // console.log("hulalala >", patientFormList?.program_info_count?.[0]
  //   ?.program_number)

  // console.log("patientformlist", patientFormList?.patient?.program_number_type);
  // // console.log( + "idhar dekho");
  useEffect(() => {
    document.title = "New Horizon | View-Patient";
    fetchfeed();
    dispatch(getAllDocuments(patientId));
    dispatch(getAllPatientForms(patientId));
    dispatch(getPatientDetails(patientId));
    if (patientId) dispatch(getPatientWithId(patientId));
  }, [patientId]);

  useEffect(() => {
    if (patientId && patientData) {
      // console.log("patientData=>", patientData);
    }
  }, [patientId, patientData]);
  // console.log("data =>", patientFormList);
  const [modal, setModal] = useState(initialModalState);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (patientData?.id) {
      getAllOptions();
    }
  }, [patientData?.id]);
  const getAllOptions = async () => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.get(
        `${API}/api/enrollment/program-sequence/${patientData?.id}`,
        {
          headers,
        }
      );
      if (res) {
        setOptions(res.data.data);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const [confimationModal, setConfimationModal] = useState({
    state: false,
    id: "",
  });

  const onOpenDelete = (id) => {
    setConfimationModal({ state: true, id });
  };
  const [fetchdata, setFetchdata] = useState(true);
  useEffect(() => {
    if (fetchdata) {
      dispatch(getAllPatientForms(patientId));
      setFetchdata(false);
    }
  }, [fetchdata]);

  const onDelete = async () => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.delete(
        `${API}/api/patient-form/${confimationModal.id}`
      );

      if (res) {
        if (res) {
          dispatch(getAllPatientForms(patientId));
          setConfimationModal({ state: false, id: "" });
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const updateFormDate = async (body, id) => {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.put(
        `${API}/api/patient-form/form-date/${id}`,
        body,
        headers
      );

      if (res) {
        dispatch(getAllPatientForms(patientId));
        toast.success("Form date updated successfully");
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const [areaOfConcernComment, setAreaOfConcernComment] = useState(
    patientFormList?.patient?.Area_of_concern?.[0]?.comment
  );

  function rearrangeArray(arr) {
    arr.sort((a, b) => {
      const dateA =
        a.appointment && a.appointment.date
          ? moment(a.appointment.date).utcOffset(0)
          : a.form_date !== null
            ? moment(a.form_date).utcOffset(0)
            : null;

      const dateB =
        b.appointment && b.appointment.date
          ? moment(b.appointment.date).utcOffset(0)
          : b.form_date !== null
            ? moment(b.form_date).utcOffset(0)
            : null;

      if (dateA && dateB) {
        return moment(dateB).diff(dateA);
      } else if (dateA) {
        return -1;
      } else if (dateB) {
        return 1;
      } else {
        return 0;
      }
    });

    return arr;
  }

  const RenderRecentGoals = () => {
    const { type, state, data, edit_id } = modal;
    let goalsWithComments = [];

    // Create a merged array of goals and additional_information
    let goals = patientFormList?.recent_goals?.[0]?.goals || [];
    let additional_information = patientFormList?.additional_information || [];

    // Map through the goals array and create a new array with the desired structure
    let recent_goals = goals.map((goal) => {
      const additional_info = additional_information.find(
        (comment) =>
          comment?.DepartmentMaster?.name?.toLowerCase() ===
          goal?.field_name?.toLowerCase()
      );

      return {
        ...goal,
        comment: additional_info?.comment,
      };
    });

    // Map through the additional_information array and create a new array with the desired structure
    let additionalComments = additional_information.map((info) => {
      const goal = goals.find(
        (goal) =>
          goal?.field_name?.toLowerCase() ===
          info?.DepartmentMaster?.name?.toLowerCase()
      );

      return {
        ...goal,
        field_name: info?.DepartmentMaster?.name,
        comment: info?.comment,
      };
    });

    // Concatenate the two arrays to get the final array
    goalsWithComments = [...recent_goals];

    return (
      <Modal
        size="max-w-2xl"
        title={`Recent Goals`}
        open={state}
        setOpen={() =>
          setModal((prev) => ({ ...prev, state: false, edit_id: "" }))
        }
      >
        <div className="grid grid-cols-1 gap-4">
          {[...new Set(goalsWithComments)].map((item) => (
            <div className="p-4 bg-white rounded-lg shadow-md border-t border-gray-200">
              <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                  Department:
                </h1>
                <p className="text-md font-semibold text-gray-900 text-left">
                  {item?.field_name}
                </p>
              </div>
              <div className="flex items-start mb-2 border-b border-gray-300 pb-2">
                <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                  Goals:
                </h1>
                <div className="text-md font-semibold text-gray-900 text-left">
                  {[...new Set(item.values.split(","))]
                    .filter((elem) => elem.length > 1)
                    .filter((e) => e !== "")
                    .map((item) => (
                      <p key={item}>&#x2022; {item}</p>
                    ))}
                </div>
              </div>
              {item.comment && (
                <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                  <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                    Comment:
                  </h1>
                  <p className="text-md font-semibold text-gray-900 text-left">
                    {item?.comment}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </Modal>
    );
  };
  useEffect(() => {
    if (patientFormList) {
      setAreaOfConcernComment(
        patientFormList?.patient?.Area_of_concern?.[0]?.comment || ""
      );
    }
  }, [patientFormList]);
  async function generateQPRsheet() {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.post(
        `${API}/api/reporting/qpr/${patientId}`,
        {
          qpr_sequence: [],
        },
        {
          headers,
        }
      );
      if (res) {
        toast.success("QPR Sheet Generated Successfully");
      }
      dispatch(getAllDocuments(patientId));
      dispatch(getAllPatientForms(patientId));
      dispatch(getPatientDetails(patientId));
      if (patientId) dispatch(getPatientWithId(patientId));
      window.location.reload();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
  const [FeedshowModal, setFeedShowModal] = useState(false);
  const [data, setData] = useState({
    loading: true,
    data: [],
  });
  async function fetchfeed() {
    let header = dispatch(getHeaders());
    const res = await axios.get(
      `${API}/api/patient/extra-feedback/${patientId}`,
      {
        headers: header,
      }
    );
    setData((prev) => ({
      loading: false,
      data: res.data.data,
    }));
  }
  const [chart, setChart] = useState({
    data: [],
    loading: true,
  });

  const programNumber = patientData?.program_number;
  console.log("🚀 ~ Viewpatient ~ programNumber:", programNumber)
  
  const numericValues = programNumber
  ?.split(" ")  
  .map((part) => part.split("/")[0].replace(/[A-Za-z]/g, ""));

  const safeNumericValues = numericValues || ["", "", ""];

  const [isEditable, setIsEditable] = useState(false);

  const [formData, setFormData] = useState({
    P: "",
    M: "",
    O: "",
  });

  const handleEditClick = () => {
    setFormData({
      P: safeNumericValues[0] || "",
      M: safeNumericValues[1] || "",
      O: safeNumericValues[2] || "",
    });
    setIsEditable(true);
  };

  // Handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Save handler (to make PUT request)
 
  const handleSave = async () => {
    const program_number = `P${formData.P}/ M${formData.M}/ O${formData.O}`;

    try {
      await app_api.put(`/api/patient/update/pmo/${patientId}`, {
        program_number,
      });
      setIsEditable(false);
      window.location.reload();
    } catch (error) {
      console.error("Error updating program number:", error);
    }
  };

  // async function fetchChart() {
  //   let header = dispatch(getHeaders());
  //   const res = await axios.get(
  //     `${API}/api/patient/extra-feedback/${patientId}`,
  //     {
  //       headers: header,
  //     }
  //   );
  //   setData((prev) => ({
  //     loading: false,
  //     data: res.data.data,
  //   }));
  // }

  const [docPreview, setDocPreview] = useState({
    state: false,
    url: "",
    name: "",
  });
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const renderPreviewDoc = () => {
    return (
      <Modal
        title="Document Preview"
        open={docPreview.state}
        setOpen={() =>
          setDocPreview((prev) => ({ ...prev, state: false, name: "" }))
        }
      >
        {docPreview.name.match(/\.(jpg|jpeg|png|gif)$/i) ? (
          <img src={docPreview.url} alt={docPreview.name} />
        ) : docPreview.name.match(/\.(pdf)$/i) ? (
          // <embed src={docPreview.url} width="100%" height="500" />
          <a href={docPreview.url}>Download</a>
        ) : (
          <a href={docPreview.url}>Download</a>
        )}
      </Modal>
    );
  };

  const extrafeedBack = () => {
    return (
      <Modal
        open={FeedshowModal}
        title="Extra Feedback"
        size="max-w-4xl"
        setOpen={() => setFeedShowModal((prev) => !prev)}
      >
        {data.loading ? (
          <FallingLinesLoader />
        ) : (
          <Table columns={extra_feedback()} data={data.data} />
        )}
      </Modal>
    );
  };
  return loading ? (
    <FallingLinesLoader />
  ) : (
    <div className=" ">
      {extrafeedBack()}
      {renderPreviewDoc()}
      <Breadcrumb pages={pages} />
      <AddFormModal showModal={showModal} setShowModal={setShowModal} />
      <ConfirmationModal
        open={confimationModal.state}
        setOpen={() =>
          setConfimationModal((prev) => ({ ...prev, state: false }))
        }
        onDelete={() => {
          onDelete();
        }}
      />
      {RenderRecentGoals()}
      <AddDocumentModal
        id={patientId}
        DshowModal={DshowModal}
        setDShowModal={setDShowModal}
        doc_id={edit_doc_id}
        setEditDocId={setEditDocId}
        edit_doc_name={edit_doc_name}
        setEditDocName={setEditDocName}
      />
      <UploadPedigreeChartModal
        id={patientId}
        DshowModal={pedigreeUploadModel}
        setDShowModal={setPedigreeUploadModel}
        doc_id={edit_doc_id}
        setEditDocId={setEditDocId}
        edit_doc_name={edit_doc_name}
        setEditDocName={setEditDocName}
      />
      <AddModal
        id={patientId}
        DshowModal={CshowModal}
        setDShowModal={setCShowModal}
        doc_id={edit_doc_id}
        setEditDocId={setEditDocId}
        edit_doc_name={edit_doc_name}
        setEditDocName={setEditDocName}
      />
      <div className="w-full">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
      </div>
      <div className="sm:flex-auto mb-5">
        <h1 className="text-lg font-medium leading-6 text-gray-900">
          View Patient
        </h1>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          This information will be displayed publicly so be careful what you
          share.
        </p>
      </div>
      {/* patient details start */}
      <div className="flex flex-row gap-5 mt-5 w-full">
        <div className=" border-solid border w-full flex  border-gray-200 bg-gray-50 h-auto pb-4  rounded-md">
          <div className="flex-1">
            <span className="block text-lg font-bold  px-4 py-4 sm:px-6">
              <h1 className=" text-gray-900 text-lg  font-medium leading-6">
                {patientData
                  ? `${patientData.firstName} ${patientData.lastName} - ${patientData.patient_id !== null
                    ? patientData.patient_id
                    : ""
                  }`
                  : null}
              </h1>
              <span className="font-bold text-gray-700 text-sm flex flex-col">
                <span>
                  Date OF Birth:{" "}
                  <span className="text-gray-500 text-sm">
                    {" "}
                    {patientData
                      ? moment(patientData.dob).format("DD/MM/YYYY")
                      : null}{" "}
                  </span>
                </span>




                <span className="font-bold text-gray-700 text-sm">
                  <span className="text-gray-500 text-sm flex flex-col pt-4">
                    <p>
                      {patientFormList?.program_info_count
                        ? patientFormList?.program_info_count?.[0]?.patient_recommendation_programs.split(
                          ","
                        ).length
                          ? patientFormList?.program_info_count?.[0]?.patient_recommendation_programs.split(
                            ","
                          )[
                          patientFormList?.program_info_count?.[0]?.patient_recommendation_programs.split(
                            ","
                          ).length - 1
                          ]
                          : patientFormList?.program_info_count?.[0]
                            ?.patient_recommendation_programs
                        : "N/A"}
                    </p>

                    

                    <div>
                      {patientData?.program_number_type !== "AUTO" &&
                        patientData?.program_number !== null && (
                          <>
                            {/* Show Edit Icon */}
                            <FaPencilAlt
                              onClick={handleEditClick}
                              style={{ cursor: "pointer" }} 
                            />

                            {/* Editable form */}
                            {isEditable && (
                              <div className="flex items-center space-x-4">
                                <div className="flex items-center space-x-2">
                                  <label className="font-medium">P:</label>
                                  <input
                                    type="number"
                                    min={0}
                                    name="P"
                                    value={formData.P}
                                    onChange={handleChange}
                                    className="border rounded px-2 py-1 w-12 text-center"
                                  />
                                </div>

                                <div className="flex items-center space-x-2">
                                  <label className="font-medium">M:</label>
                                  <input
                                    type="number"
                                    min={0}
                                    name="M"
                                    value={formData.M}
                                    onChange={handleChange}
                                    className="border rounded px-2 py-1 w-12 text-center"
                                  />
                                </div>

                                <div className="flex items-center space-x-2">
                                  <label className="font-medium">O:</label>
                                  <input
                                    type="number"
                                    min={0}
                                    name="O"
                                    value={formData.O}
                                    onChange={handleChange}
                                    className="border rounded px-2 py-1 w-12 text-center"
                                  />
                                </div>

                                <button
                                  onClick={handleSave}
                                  className="ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </>
                        )}
                    </div>




                    {!isEditable && (
                      <p>{patientData?.program_number || "N/A"}</p>
                    )}
                  </span>
                </span>



              </span>
            </span>
            <div className="text-sm  text-gray-500">
              <div className="px-4 py-1 space-y-1 sm:px-6">
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <BsGenderAmbiguous /> Gender:{" "}
                  {patientData && patientData.gender}
                </p>
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <BsFilePersonFill /> Age: {patientData && patientData.age}
                </p>
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <BsFillTelephoneFill /> Mobile:{" "}
                  {patientData ? patientData.phoneNo : "N/A"}
                </p>
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <RiEarthFill /> Address:{" "}
                  {patientData ? patientData.address : "N/A"}
                </p>
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <RiEarthFill /> Location:{" "}
                  {patientData ? patientData?.location?.name : "N/A"}
                </p>
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <RiEarthFill /> Last OPD Date:{" "}
                  {loading === false &&
                    patientFormList?.appointments &&
                    moment(patientFormList?.appointments["0"]?.date).format(
                      "DD/MM/YYYY"
                    )}
                </p>
                <p className="text-sm flex items-center gap-2">
                  {" "}
                  <RiEarthFill /> MemberShip Type:{" "}
                  {patientFormList && patientFormList["Membership Type"]
                    ? patientFormList["Membership Type"]
                    : "N/A"}
                </p>
                <p className="mt-2 text-sm flex items-center gap-2">
                  {" "}
                  <BsFilePersonFill /> Referral:{" "}
                  {patientData && patientFormList.patient?.referral?.name
                    ? patientFormList.patient?.referral?.name
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="w-[30%] flex items-center justify-center">
            <FaUserAlt size={100} className="text-gray-400" />
          </div>
        </div>
      </div>
      {/* patient details end here */}
      <div className="mt-4 sm:flex-auto ">
        <Formik
          initialValues={{
            comment: areaOfConcernComment,
          }}
          enableReinitialize={true}
          onSubmit={async (values, action) => {
            const headers = await dispatch(getHeaders());
            // console.log(headers);
            const res = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/patient/comment/area-of-concern/${patientId}`,
              values,
              {
                headers: headers,
              }
            );

            if (res.status === 201) {
              setAreaOfConcernComment(res.data.comment);
              toast.success("Comment Save Successfully");
            }
          }}
        >
          {(Formik) => (
            <form onSubmit={Formik.handleSubmit}>
              <div className="mt-4">
                <div className="py-1">
                  <div className="flex flex-col mt-2">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Area of Concern
                    </label>
                    <textarea
                      name="comment"
                      id="comment"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.comment}
                      rows={3}
                      className="w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Area of Concern"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex items-end justify-end">
                <button
                  type="submit"
                  className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-full flex items-center justify-start gap-4">
          <button
            className="mt-4 text-center w-[15%] rounded-md border p-1 text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
            onClick={() =>
              setModal((prev) => ({
                ...prev,

                state: true,
              }))
            }
          >
            Recent Goals
          </button>
          <button
            className="mt-4 text-center w-[15%] rounded-md border p-1 text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
            onClick={() => setFeedShowModal((prev) => true)}
          >
            Extra Feedbacks
          </button>
        </div>
        <button
          className="mt-4 text-center w-[15%] rounded-md border p-1 text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
          onClick={() => setShowModal(true)}
        >
          Add Form
        </button>
      </div>
      <Table
        columns={view_patients_columns({
          onOpenDelete,
          updateFormDate,
          options,
          setFetchdata,
          generateQPRsheet,
        })}
        data={rearrangeArray(patientFormsList)}
      // onEditClose={cleanModalData}
      />

      <div className="flex mt-4 flex-row gap-5">
        <div className="border-solid border border-gray-200 bg-white w-full h-48 rounded-md">
          <Link
            to="/patient-apointment"
            className="block text-lg  font-bold border-b border-gray-200 bg-gray-50 px-4 py-4 sm:px-6"
          >
            <h1 className="hover:underline text-gray-900 text-lg font-medium leading-6">
              Appointments
            </h1>
          </Link>

          <Link to="/petient-details" className="text-sm text-gray-500">
            <div className="">
              <div className="flex flex-col">
                <div className="overflow-y-auto">
                  <div className=" py-1">
                    <div className="overflow-y-scroll h-28 shadow ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full  overflow-y-auto divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Start Time
                            </th>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              End Time
                            </th>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Doctor Name
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {loading ? (
                            <FallingLinesLoader />
                          ) : (
                            patientFormList &&
                            patientFormList.length !== 0 &&
                            patientFormList?.appointments.map((person) => (
                              <tr>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                  {moment(person.date).format("DD/MM/YYYY")}
                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                  {person.start_time}
                                  {/* {moment(person.start_time).format("hh:mm A")} */}
                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                  {person.end_time}
                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                  {person.doctor
                                    ? "Dr. " +
                                    person.doctor.first_name +
                                    " " +
                                    person.doctor.last_name
                                    : "N/A"}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="bg-white border-solid border border-gray-200 w-full h-48 rounded-md">
          <Link
            to="/invoices"
            className="block text-lg  font-bold border-b border-gray-200 bg-gray-50 px-4 py-4 sm:px-6"
          >
            <h1 className="hover:underline text-gray-900 text-lg font-medium leading-6">
              Invoices
            </h1>
          </Link>
          <div className="overflow-y-scroll h-28 shadow ring-1 ring-black ring-opacity-5">
            <table className="min-w-full divide-y overflow-y-auto divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="text-center text-sm font-semibold text-gray-900 p-1"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="text-center text-sm font-semibold text-gray-900 p-1"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="text-center text-sm font-semibold text-gray-900 p-1"
                  >
                    PDF
                  </th>
                  <th
                    scope="col"
                    className="text-center text-sm font-semibold text-gray-900 p-1"
                  >
                    Payment Date
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {loading ? (
                  <FallingLinesLoader />
                ) : (
                  patientFormList &&
                  patientFormList.length !== 0 &&
                  patientFormList?.invoices.map((item) => (
                    <tr>
                      <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                        {item?.amount}
                      </td>
                      <td className=" flex items-center justify-center whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                        {item?.success ? (
                          <GrCheckmark className="text-green-700 " />
                        ) : (
                          <RxCross2 className="text-red-700" />
                        )}
                      </td>
                      <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                        {item?.pdf ? (
                          <span className="inline-flex px-3 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full">
                            <a
                              className="underline text-indigo-500"
                              target={"_blank"}
                              href={`${process.env.REACT_APP_API_URL}/uploads/${item.pdf}`}
                            >
                              Pdf
                            </a>
                          </span>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                        {item?.success
                          ? moment(item?.updatedAt).format("DD/MM/YYYY")
                          : "-"}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5 mt-5 w-full">
        {/* patient */}
        {/* patient  */}
        <div className="bg-white border-solid border border-gray-200 w-full md:w-1/2 h-48 rounded-md">
          <div className="justify-between items-center  flex  border-b border-gray-200 bg-gray-50 px-4 py-4 sm:px-6">
            <h1 className="hover:underline  text-gray-900 w-1/2 text-lg font-medium leading-6">
              Quarterly Report
            </h1>
          </div>

          <div className="text-sm text-gray-500">
            <div className="">
              <div className="flex flex-col">
                <div className="overflow-y-auto">
                  <div className=" py-1">
                    <div className="overflow-y-scroll h-28 shadow ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full  overflow-y-auto divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Create At
                            </th>

                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {patientFormList?.qpr_report_list &&
                            patientFormList?.qpr_report_list?.map(
                              (item, indx) => (
                                <tr>
                                  <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                    {item?.name}
                                  </td>

                                  <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                    <Link
                                      to={`/qpr-sheet/${item.patient_id
                                        }?q=${item.name.replace(" - ", "%20")}`}
                                      className="inline-flex px-3 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full"
                                    >
                                      View
                                    </Link>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white border-solid border border-gray-200 w-full md:w-1/2 h-48 rounded-md">
          <div className="justify-between items-center  flex  border-b border-gray-200 bg-gray-50 px-4 py-4 sm:px-6">
            <h1 className="hover:underline  text-gray-900 w-1/2 text-lg font-medium leading-6">
              Documents
            </h1>
            <button
              onClick={() => setDShowModal(true)}
              className="text-center rounded-md border px-2 p-1 text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
            >
              Add Document
            </button>
          </div>

          <div className="text-sm text-gray-500">
            <div className="">
              <div className="flex flex-col">
                <div className="overflow-y-auto">
                  <div className=" py-1">
                    <div className="overflow-y-scroll h-28 shadow ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full  overflow-y-auto divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Document Name
                            </th>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Document
                            </th>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Created At
                            </th>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {docloading ? (
                            <FallingLinesLoader />
                          ) : (
                            documentList?.map((item) => {
                              return (
                                <tr>
                                  <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                    {item?.document_name}
                                  </td>
                                  <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                    <span className="inline-flex px-3 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full">
                                      <a
                                        className="underline text-indigo-500"
                                        target={"_blank"}
                                        href={item.path}
                                      >
                                        Open
                                      </a>
                                    </span>
                                  </td>
                                  <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                    <button
                                      onClick={(e) => {
                                        setDShowModal(true);
                                        setEditDocId(item?.id);
                                        setEditDocName(item?.document_name);
                                      }}
                                      className="inline-flex px-3 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full"
                                    >
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white border-solid border border-gray-200 w-full md:w-1/2  h-48 rounded-md">
          <div className=" items-center  flex  border-b border-gray-200 bg-gray-50 px-4 py-4 sm:px-6">
            <h1 className="hover:underline  text-gray-900 w-1/2 text-lg font-medium leading-6">
              Pedigree Chart
            </h1>
            <button
              onClick={() => setPedigreeUploadModel(true)}
              className="text-center rounded-md border px-2 p-1 text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700 ml-auto mr-3"
            >
              Upload Chart
            </button>
            <button
              onClick={() => setCShowModal(true)}
              className="text-center rounded-md border px-2 p-1 text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
            >
              Add Chart
            </button>
          </div>
          <div className="text-sm text-gray-500">
            <div className="">
              <div className="flex flex-col">
                <div className="overflow-y-auto">
                  <div className=" py-1">
                    <div className="overflow-y-scroll h-28 shadow ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full  overflow-y-auto divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Document Name
                            </th>

                            <th
                              scope="col"
                              className="text-center text-sm font-semibold text-gray-900 p-1"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {docloading ? (
                            <FallingLinesLoader />
                          ) : (
                            patientFormList?.patient?.PerdigreeChart?.map(
                              (item) => {
                                return (
                                  <tr>
                                    <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                      {item?.name}
                                    </td>

                                    <td className="whitespace-nowrap text-sm font-medium text-gray-900 text-center p-1">
                                      {!item?.file && (
                                        <button
                                          onClick={(e) => {
                                            navigate(`/genogram/${item?.id}`);
                                          }}
                                          className="inline-flex px-3 ml-3 text-xs leading-5 text-black-600 bg-gray-300 rounded-full"
                                        >
                                          Edit
                                        </button>
                                      )}

                                      {item?.file && (
                                        <a
                                          href={`${process.env.REACT_APP_API_URL}/uploads/${item?.file}`}
                                          target="_blank"
                                          className="inline-flex px-3 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full cursor-pointer"
                                        >
                                          View
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* doc */}
    </div>
  );
}

export default Viewpatient;
